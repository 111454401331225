import styled from 'styled-components';



export const CustomText = styled.h1`
    font-size: ${({ fontSize }) => fontSize || "1.5em"};
    font-weight: ${({ fontWeight }) => fontWeight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    font-family: ${({ fontFamily }) => fontFamily || "sans-serif"};
    line-height: ${({ fontLineHeight }) => fontLineHeight || "normal"};
    letter-spacing: normal;
    color: ${({ fontColor }) => fontColor || "#000000"};
    margin: ${({ fontMargin }) => fontMargin || "0px"};
    padding: ${({ fontPadding }) => fontPadding};
    text-align: ${({ textAlign }) => textAlign};
    width: ${({ width }) => width};
    display: ${({ fontDisplay }) => fontDisplay};
    border-radius: ${({ textBorderRadius }) => textBorderRadius};
    background: ${({ textBackground }) => textBackground};

    &.center-text{
        text-align: center;
    }

    
    &.right-text{
        float: right;
    }
    
`





export const CustomSpan = styled.span`
    font-size: ${({ fontSize }) => fontSize || "1.5em"};
    font-weight: ${({ fontWeight }) => fontWeight || "bold"};
    font-style: ${({ fontStyle }) => fontStyle || "normal"};
    font-stretch: normal;
    font-family: ${({ fontFamily }) => fontFamily || "sans-serif"};
    line-height: ${({ fontLineHeight }) => fontLineHeight || "normal"};
    letter-spacing: normal;
    color: ${({ fontColor }) => fontColor || "#000000"};
    margin: ${({ fontMargin }) => fontMargin || "0px"};
    text-align: ${({ textAlign }) => textAlign};
    width: ${({ width }) => width};
    display: ${({ fontDisplay }) => fontDisplay};
    

    &.center-text{
        text-align: center;
    }

    
    &.right-text{
        float: right;
    }
    
`