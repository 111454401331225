import React from "react";
import styled from "styled-components";
import { Marginer } from "../marginer";

class Footer extends React.Component {

  render (){
    return (
      <FooterContainer>
        <Marginer direction="vertical" margin="5em" />
        {/* <Logo small /> */}
        <Marginer direction="vertical" margin="1em" />
        <MotivationalText>Providing Limitless Education </MotivationalText>
        <MotivationalText>through Technology</MotivationalText>
        <Marginer direction="vertical" margin="1em" />
        <Link className="startLearning" href={this.props.link || "https://skideo.tv"} rel="noreferrer" target="_blank">{this.props.caption || "Start Learning Now"}</Link>
  
        <Marginer direction="vertical" margin="5em" />
        <AccessibilityContainer>
          <PrivacyContainer>
            <Link href="/teacher">Teachers</Link>
            <Link href="/legal/privacy">Privacy</Link>
            <Link href="/teacher">Terms of use</Link>
          </PrivacyContainer>
          <SocialContainer>
            info@skideo.tv
          </SocialContainer>
        </AccessibilityContainer>
        <RightsReserved>&copy; {new Date().getFullYear()} Skideo All rights reserved</RightsReserved>
      </FooterContainer>
    );

  }
}

export default Footer


const FooterContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1f1f1f;
  position: relative;

  .startLearning{
    text-decoration: none;
    padding: 7px 15px;
    border-radius: 5px;
    background-color: #8d0303;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    
    border: 2px solid transparent;
    transition: all 220ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      border: 2px solid #8d0303;
      color: #fff;
    }

  }
`;

const MotivationalText = styled.h1`
  font-size: 25px;
  font-weight: 500;
  line-height: 1.4;
  color: #fff;
  margin: 0;
`;

const AccessibilityContainer = styled.div`
  width: 80%;
  display: flex;
  border-top: 1px solid #cdcdcd;
  padding-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  color: #fff;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    width: 90%;
    padding-left: 8px;
    padding-right: 8px;
  }
`;

const PrivacyContainer = styled.div`
  display: flex;
`;

const SocialContainer = styled.div`
  display: flex;
`;

const Link = styled.a`
  color: #fff;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;

  &:not(:last-of-type) {
    margin-right: 11px;

    @media screen and (max-width: 480px) {
      margin-right: 9px;
    }
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }

  &:hover {
    color: #adadad;
    text-decoration: underline;

  }
`;

const RightsReserved = styled.div`
  width: fit-content; 
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 12px;
`;
