import React from "react";
import styled from "styled-components";
import { Card } from "../../components/card";
import { CustomText } from "../../components/text";
import TeacherIcon from "../../images/icons/teacher.svg"
import LegacyIcon from "../../images/icons/graduation-cap.svg"
import TechnologyLeverageIcon from "../../images/icons/online-course.svg"
import InventivesIcon from "../../images/icons/wallet.svg"
import QuickStartIcon from "../../images/icons/shuttle-outline.svg"
import Footer from "../../components/footer/index";

class Teacher extends React.Component {


  render() {
    return (
      <PageContainer>

        {/* Where would you be without a teacher today, Teachers have the most power */}
        <Card className="landingBackground" width="100%" height="90vh">
          <div>

            <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="50px">
              Maximize your impact by reaching a wider audience
            </CustomText>
            <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="30px" fontWeight="normal">
              Teachers play a major role in shaping the future
            </CustomText>
          </div>

        </Card>

        {/* let's take teaching out of the classroom. */}


        <Card className="greenCard" width="48%" minHeight="48vh" textAlign="center" margin="1em 0">
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="40px">
            Upgrade Education
          </CustomText>
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="21px" fontWeight="normal">
            Take teaching out of the classroom.
          </CustomText>
          <img src={TeacherIcon} alt="teacher icon" height="150px" />
        </Card>

        {/* Our students, our future, our leaders and 
        our legacy. Teachers play a big role in the future
         of the generation. At ae of 24 at most 45 percent of 
         an average students time is with a teacher, hence the 
         crucial role they play in the mindset and upbringing of 
         the student
         */}

        <Card className="yellowCard" width="48%" minHeight="48vh" textAlign="center" margin="1em 0">
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="40px">
            Nurture the Generation
          </CustomText>
          <CustomText fontColor="#f5f5f7" fontMargin="1em auto" width="80%" fontSize="21px" fontWeight="normal">
            Our students, our future, our leaders and our legacy.
            Teachers play a significant role in shaping the future of
            the generation. At age 24, about 45 percent
            of an average student's time is spent with a teacher;
            hence, you play a crucial role in shaping the mindset
            and upbringing of students.
          </CustomText>
          <img src={LegacyIcon} alt="teacher icon" height="150px" />
        </Card>

        {/* Let's leverage. technology is not rocket scince. It is a tool that could actially make out works way easier than we imagined. */}

        <Card className="redCard" width="48%" minHeight="48vh" textAlign="center" margin="1em 0">
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="40px">
            Interactive
          </CustomText>
          <CustomText fontColor="#f5f5f7" fontMargin="1em auto" width="70%" fontSize="21px" fontWeight="normal">
           Harness the power of technology to provide the best education to a wider audience.
          </CustomText>
          <img src={TechnologyLeverageIcon} alt="teacher icon" height="150px" />
        </Card>

        {/* Get incentiviced. Skideo promises to pay 
          you every single dime your contents earn.
          Imagine how cool it will be for your contents 
          to keep generating money 10 years after you
          first recorded it. 
        */}
        <Card className="blueCard" width="48%" minHeight="48vh" textAlign="center" margin="1em 0">
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="40px">
            Get Loaded
          </CustomText>
          <CustomText fontColor="#f5f5f7" fontMargin="1em auto" width="70%" fontSize="21px" fontWeight="normal">
            Get incentivised. Skideo pays you for your content.
          </CustomText>
          <img src={InventivesIcon} alt="teacher icon" height="150px" />
        </Card>

        {/* Get onboarded. A quick start guide on how to get conversant with the entire application */}
        <Card className="blackCard" width="98%" minHeight="48vh" textAlign="center" margin="1em 0">
          <CustomText fontColor="#f5f5f7" fontMargin="1em" fontSize="40px">
            Get onboarded
          </CustomText>
          <CustomText fontColor="#f5f5f7" fontMargin="1em auto" width="70%" fontSize="21px" fontWeight="normal">
            A quick start guide on how to get conversant with the entire application
          </CustomText>
          <img src={QuickStartIcon} alt="teacher icon" height="150px" />
          <CustomText fontColor="#f5f5f7"
            textBackground="#6c757d"
            // textBackground="red"
            textBorderRadius="20px"
            fontPadding="5px 10px"
            fontMargin="0px auto 20px"
            fontSize="15px"
            width="fit-content"
          >
            Coming soon
          </CustomText>
        </Card>

        <Card className="greyCard" width="90%" textAlign="center" margin="2em 0" padding="2em 0">
          <CustomText fontColor="#1d1d1f" fontMargin="1em" fontSize="40px">
            Get Started with Skideo Lab
          </CustomText>
          <CustomText fontColor="#1d1d1f" fontMargin="1em auto" width="70%" fontSize="21px" fontWeight="normal">
            The lab where everything is manufactured
          </CustomText>

          <a href="https://lab.skideo.tv">
            Register
          </a>
        </Card>
        <Footer link="https://lab.skideo.tv" caption="Start Teaching Now" />

      </PageContainer>
    );
  }
}

export default Teacher




const PageContainer = styled.div` 
  width: 100%;
  height: 100%;
  overflow-x:hidden;
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  color: #f5f5f7;
  background: #edf2fb;

  .landingBackground{
    &>div{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      @media only screen and (max-width: 800px) {
        width: 100%;
        &>h1{
          margin: 12px;

        }
      }
    }
    /* https://www.gradientmagic.com/collection/popular/gradient/1599427515870 */
    background-image: repeating-linear-gradient(
      45deg, hsla(64,83%,54%,0.05) 0px, 
      hsla(64,83%,54%,0.05) 1px,transparent 1px, 
      transparent 11px,hsla(64,83%,54%,0.05) 11px, 
      hsla(64,83%,54%,0.05) 12px,transparent 12px, transparent 32px),
      repeating-linear-gradient(90deg, hsla(64,83%,54%,0.05) 0px, 
      hsla(64,83%,54%,0.05) 1px,transparent 1px, 
      transparent 11px,hsla(64,83%,54%,0.05) 11px, 
      hsla(64,83%,54%,0.05) 12px,transparent 12px, transparent 32px),
      repeating-linear-gradient(0deg, hsla(64,83%,54%,0.05) 0px, 
      hsla(64,83%,54%,0.05) 1px,transparent 1px, transparent 11px,
      hsla(64,83%,54%,0.05) 11px, 
      hsla(64,83%,54%,0.05) 12px,
      transparent 12px, transparent 32px),
      repeating-linear-gradient(135deg, 
        hsla(64,83%,54%,0.05) 0px, 
        hsla(64,83%,54%,0.05) 1px,
        transparent 1px, 
        transparent 11px,
        hsla(64,83%,54%,0.05) 11px, 
        hsla(64,83%,54%,0.05) 12px,
        transparent 12px, transparent 32px),
        linear-gradient(90deg, rgb(41, 27, 158),
        rgb(249, 77, 212)
        );
  }

  .greenCard{
    background: #205430;
    background-image: linear-gradient(320deg, rgb(13, 141, 81) 0%, rgb(32, 84, 48) 100%);

    img{
      margin: 1em;
      background-color: #5cc14c;
      padding: 2em;
      border-radius: 20px;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      border-radius: 5px;
    }

  }

  .yellowCard{
    background-image: linear-gradient(320deg, rgb(255, 123, 0) 0%, rgb(255, 183, 0) 100%);

    img{
      margin: 1em;
      background-color: #f7ca46;
      padding: 2em;
      border-radius: 20px;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      border-radius: 5px;
    }
  }

  .redCard{
    background-image: linear-gradient(320deg, rgb(239, 99, 81) 0%, rgb(255, 92, 138) 100%);

    img{
      margin: 1em;
      background-color: #f94144;
      padding: 2em;
      border-radius: 20px;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      border-radius: 5px;
    }
    
  }

  .blueCard{
    background-image: linear-gradient(320deg, rgb(97, 165, 194) 0%, rgb(1, 42, 74) 100%);

    img{
      margin: 1em;
      background-color: #118ab2;
      padding: 2em;
      border-radius: 20px;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      border-radius: 5px;
    }
  }

  .blackCard{
    background-image: linear-gradient(320deg, #6c757d 0%, #212529 100%);

    img{
      margin: 1em;
      background-color: #161616;
      padding: 2em;
      border-radius: 20px;
    }

    @media only screen and (max-width: 800px) {
      width: 95%;
      border-radius: 5px;
    }

    &:hover{
      transform: scale(0.95);
      border-radius: 10px;
      cursor: pointer;
      box-shadow:  16px 16px 44px #0a0a0a10, 
                    -16px -16px 44px #282a2810;
      transition: 0.3s all ease-in-out;
      /* background: #fbf5d0;       */
      
  }

  }

  
  .greyCard{
    background-color: #fafafa;
    display: block;
    border-radius: 10px;

    img{
      margin: 1em;
      padding: 2em;
      border-radius: 20px;
    }

    a{
      display: block;
      color: #fff;
      text-decoration: none;
      background-color: #923d41;
      padding: 10px 20px;
      margin: 10px auto;
      width: fit-content;
      border-radius: 20px;
      font-size: 17px;

    }
  }
  
`;