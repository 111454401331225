import styled from 'styled-components';


export const Card = styled.div`
    color: ${({ color }) => color || "#ffffff"};
    display: ${({ display }) => display || "block"};
    background: ${({ background }) => background || "#ffffff"};
    border: ${({border}) => border || 0};
    padding: ${({ padding }) => padding || "0px 0px"};
    margin: ${({ margin }) => margin || "0px 0px"};
    border-radius: ${({radius}) => radius || "2px"};
    width: ${({width}) => width || "auto"};
    height: ${({height}) => height || "auto"};
    min-height: ${({minHeight}) => minHeight};
    box-shadow: ${({shadow}) => shadow || "none"};
    justify-content: ${({space}) => space || "normal"};
    text-align: ${({textAlign}) => textAlign};
    align-items: ${({alignItems}) => alignItems};
    flex-flow: ${({flexFlow}) => flexFlow};
  
`;

